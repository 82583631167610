import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";

// eslint-disable-next-line
export const ProductTemplate = ({
  title,
  helmet,
  resources,
}) => {
  return (
    <section className="section">
      {helmet || ""}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            {resources && resources.length ? (
              <div style={{ marginTop: `4rem` }}>
                <h4>Resources</h4>
                <ul className="taglist">
                  {resources.map((resource) => (
                    <li key={resource + `tag`}>
                      <Link to={`/tags/${kebabCase(resource)}/`}>{resources}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

ProductTemplate.propTypes = {
  title: PropTypes.string,
  helmet: PropTypes.object,
  resources: PropTypes.array,
};

const product = ({ data }) => {
  const { markdownRemark: product } = data;

  return (
    <Layout>
      <ProductTemplate
        helmet={
          <Helmet titleTemplate="%s | Product">
            <title>{`${product.frontmatter.title}`}</title>
          </Helmet>
        }
        title={product.frontmatter.title}
        resources={product.frontmatter.resources}
      />
    </Layout>
  );
};

product.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default product;

export const productpageQuery = graphql`
  query ProductByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        resources
      }
    }
  }
`;
